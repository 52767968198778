import { 
    Col, 
    Container, 
    Row,
    FormGroup,
    Input, 
    Label,
    Button
} from "reactstrap";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Icon from '@mui/material/Icon';

function IncorrectPage(){
    return(
        <Container style={{marginTop: '6rem', textAlign: "center"}}>
            <Row>
                <Col> 
                    <ErrorOutlineIcon style={{color:'red', fontSize: '4rem'}}/>
                </Col>
            </Row>
            <Row style={{marginTop:'1rem'}}>
                <Col>
                    <h2>Algo deu errado!</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Você tentou acessar um link expirado ou incorreto.</h3>
                </Col>
            </Row>
            <Row style={{marginTop:'2rem'}}>
                <Col>
                    <Button size="lg"  style={{backgroundColor: '#32699A'}} onClick={() => { window.location.href = "https://pdpre.interplayers.com.br/11v1/" }}>
                        Voltar para o Portal da Drogaria
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

export default IncorrectPage;